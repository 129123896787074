<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data">
  <v-container id="PROFILAZIONEUTENTI" fluid tag="section"  >
    <v-row>
      <v-col :cols="isEditProfiloUtente ? '4' : '12'">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-account-group"
          :title="$t('Profilazione utenti')"
          class="px-5 py-3"
        >
          <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
            <v-row>
              <v-col>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('profilazioneutenti.ricerca')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              </v-col>
              <v-col align="end">
                <v-btn color="darkred" width="150px" @click="onNuovoUtente">
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  {{ $t("profilazioneutenti.nuovoUtente") }}
                </v-btn>
                <v-btn  color="darkred" width="180px" @click="onExportGrid"  v-show="false">
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>

              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            style="max-height:600px; overflow:auto"
            id="tblUtenti"
            :items="profilazioneUtenti.source"
            :headers="headersColumns"
            :search="search"
            :value="[profilazioneUtenti.selected]"
            @click:row="onTableRowClick"
            :items-per-page="-1"
            single-select
            class="elevation-1"
            :loading="isloading_dataTable"
            loading-text="Caricamento dati in corso..."
          >
          </v-data-table>
        </base-material-card>
      </v-col>

      <v-col v-if="isEditProfiloUtente" cols="8">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-account-settings"
          :title="$t('profilazioneutenti.user') + ': ' + profilazioneUtenti.selected.cUsername"
          class="px-5 py-3"
        >
          <v-card flat>
            <v-row>
              <v-card-title
                class="black--text"
                style="margin-bottom: 20px; margin-top: 20px"
                >{{ $t("anagrafica") }}
              </v-card-title>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="profilazioneUtenti.selected.cUsername"
                  :label="$t('profilazioneutenti.username')"
                  :rules="[rules.required]"
                  maxlength="50"
                  :disabled="shouldDisable"
                >
                </v-text-field>
              </v-col>
                <v-col>
                <v-text-field
                  v-model="profilazioneUtenti.selected.cEmail"
                  :label="$t('profilazioneutenti.email')"
                  :rules="[rules.required, rules.email]"
                  maxlength="50"
                  :disabled="shouldDisable"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="profilazioneUtenti.selected.cName"
                  :label="$t('profilazioneutenti.name')"
                  :rules="[rules.required]"
                  maxlength="50"
                  :disabled="shouldDisable"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="profilazioneUtenti.selected.cSurname"
                  :label="$t('profilazioneutenti.surname')"
                  :rules="[rules.required]"
                  maxlength="50"
                  :disabled="shouldDisable"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="profilazioneUtenti.selected.xNotes"
                  :label="$t('profilazioneutenti.xNotes')"
                  :disabled="shouldDisable"
                  outlined
                >
                </v-textarea>
              </v-col>
            </v-row>            
            <v-row dense>
              <v-col :disabled="shouldDisable">
                <v-card-title
                  class="black--text"
                  style="margin-bottom: 20px; margin-top: 20px"
                  >{{ $t("profilazioneutenti.profili") }}
                </v-card-title>
                <v-data-table
                  dense
                  hide-default-footer
                  :headers="headersProfili"
                  :items="t_profili"
                  item-value="id"
                  class="elevation-1"
                >
                  <template v-slot:[`item.isProfiloSel`]="{ item }">
                    <v-checkbox
                      v-model="item.isProfiloSel"
                      dense
                      :disabled="shouldDisable"
                      :ripple="false"
                      @change="onProfiloClick(item, $event)"
                    ></v-checkbox>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :disabled="shouldDisable">
                <v-card-title
                  class="black--text"
                  style="margin-bottom: 20px; margin-top: 20px"
                  >{{ $t("profilazioneutenti.enti") }}
                </v-card-title>
                <v-data-table
                  dense
                  hide-default-footer
                  :headers="headersEnti"
                  :items="eEnti"
                  item-value="id"
                  class="elevation-1"
                >
                  <template v-slot:[`item.isResp`]="{ item }">
                    <v-checkbox
                      v-model="item.isResp"
                      dense
                      :disabled="shouldDisable"
                      :ripple="false"
                    ></v-checkbox>
                  </template>
                  <template v-slot:[`item.isUtiliz`]="{ item }">
                    <v-checkbox
                      v-model="item.isUtiliz"
                      dense
                      :disabled="shouldDisable"
                      :ripple="false"
                    ></v-checkbox>
                  </template>
                  <template v-slot:[`item.isEnteSel`]="{ item }">
                    <v-checkbox
                      v-model="item.isEnteSel"
                      dense
                      :disabled="shouldDisable"
                      :ripple="false"
                      @change="onEnteClick(item, $event)"
                    ></v-checkbox>
                  </template>
                  <template v-slot:[`item.eServizi.cSigla`]="{ item }">
                    <!-- <span>ente id: {{ item.id }}</span> -->
                    <v-select
                      v-model="item.eServizi"
                      :items="eServizi[item.id]"
                      return-object
                      item-text="cSigla"
                      item-value="id"
                      :label="$t('servizi.servizio')"
                      :disabled="shouldDisable"
                    ></v-select>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-divider class="mx-4"></v-divider>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-alert
                id="alertDettaglio"
                shaped
                :value="isAlertDetailVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
            </v-row>

            <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
              <v-btn
                tile
                color="darkred"
                v-if="!isHidden"
                v-on:click="isHidden = true"
                @click="onEditClick"
              >
                <v-icon left> mdi-pencil </v-icon>
                {{ $t("modifica") }}
              </v-btn>
              <v-btn
                tile
                color="darkred"
                v-if="isHidden"
                @click="onSaveClick"
              >
                <v-icon left> mdi-content-save </v-icon>
                {{ $t("salva") }}
              </v-btn>              
              <v-btn
                  tile
                  color="darkred"
                  @click="onDisattivaClick"
                >
                  <v-icon left> mdi-delete </v-icon>
                  {{ $t("disattiva") }}
              </v-btn>
              <v-btn
                  tile
                  color="darkred"
                  @click="onResetPwdClick"
                >
                  <v-icon left> mdi-lock-reset </v-icon>
                  {{ $t("resetPassword") }}
              </v-btn>
              <v-btn id="btnChiudi" tile color="darkred" @click="onCloseDetail">
                <v-icon left> mdi-door-open </v-icon>
                {{ $t("chiudi") }}
              </v-btn>
              <v-dialog v-model="dialogDisattivaUtente" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Sei sicuro di voler disattivare l'utente selezionato?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDisattivaUtente"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmDisattivaUtente"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-alert
        id="alertGrid"
        shaped
        :value="isAlertGridVisible"
        :type="alertType"
        dismissible
      >
        {{ messaggioAlert }}
      </v-alert>
    </v-row>

  </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";
import Utils from "@/utils/Utils";

import UtentiService from "@/service/UtentiService";
import TipologicheService from "@/service/TipologicheService";
import EntiService from "@/service/EntiService";
import ServiziService from "@/service/ServiziService";

@Component
export default class ProfilazioneUtenti extends Vue {
  
  profilazioneUtenti = {
    selected: null,
    source: [],
  };

  headersColumns = [
    {
      text: "Username",
      align: "start",
      value: "cUsername",
    },
    { text: "Nome", value: "cName" },
    { text: "Cognome", value: "cSurname" },
  ];

  headersProfili = [
    {
      text: "Profilo",
      align: "start",
      value: "cDescription",
    },
    { text: "Selezione", value: "isProfiloSel", sortable: false },
  ];

  headersEnti = [
    {
      text: "Ente",
      align: "start",
      value: "cDescrizione",
    },
    { text: "Responsabile", value: "isResp", sortable: false },
    { text: "Utilizzatore", value: "isUtiliz", sortable: false },
    { text: "Selezione", value: "isEnteSel", sortable: false },
    { text: "Servizio", value: "eServizi.cSigla", sortable: false },
  ];



  t_profili = [];

  eEnti = [];

  eProfiliSel = [];

  userTemplate = null;

  eServizi = [];

  cSel = null;

  isEditProfiloUtente = false;
  isCreatedCorrectly = false;
  isAlertGridVisible = false;
  isAlertDetailVisible = false;
  isloading_dataTable = false;
  shouldDisable = true;
  isHidden = false;
  saveClicked = false;
  isNewUser = false;
  dialogDisattivaUtente = false;

  dimension = 0;

  messaggioAlert = "";
  alertType = "error";
  search = "";

  rules = Utils.rules;

  created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "W_PROFILAZIONEUTENTE");
    switch (verLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true
        this.loadTemplateUtente();
        this.loadRuoli();
        this.loadUtenti(false);
        this.loadEnti();
        // TODO
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }
  }

  onExportGrid() {
    // TODO
  }

  onNuovoUtente() {
    // Chiudo l'oggetto precedente prima di aprire il nuovo
    this.saveClicked = false;
    this.isNewUser = true;

    // Impostazione variabili
    this.isEditProfiloUtente = true;
    this.shouldDisable = false;
    this.isHidden = true;

    // Svuoto oggetti collegati
    this.eProfiliSel = [];
    for (let j = 0; j < this.t_profili.length; j++) {
      var profiloTipol = this.t_profili[j];
      profiloTipol.isProfiloSel = false;
    }

    this.profilazioneUtenti.selected = Object.assign({}, this.userTemplate);

    this.onContainerChange();

    this.$refs.form.resetValidation();
  }
  
  onTableRowClick(utenteSel) {
    // Setto la riga selezionata
    this.profilazioneUtenti.selected = utenteSel;
    //this.titolo = "$t('page.veicolo.titolo')" + " " + this.veicoloSel.cSigla;
    console.log("Utente selezionato: ");
    console.log(this.profilazioneUtenti.selected);

    // ******************************
    // CIX
    // UTILIZZARE VARIABILE COPIA CHE FA RIFERIMENTO ALL'ARRAY PRINCIPALE CHE POPOLA LA GRIGLIA ALTRIMENTI NON FUNZIONA
    // ******************************
    this.eProfiliSel = [];
    // Svuoto la selezione dalla tabella principale
    for (let j = 0; j < this.t_profili.length; j++) {
      var profiloTipol = this.t_profili[j];
      profiloTipol.isProfiloSel = false;
    }
    // Setto i profili selezionati
    for (let i = 0; i < utenteSel.ruoliUtente.length; i++) {
      var profiloSel = utenteSel.ruoliUtente[i];
      for (let j = 0; j < this.t_profili.length; j++) {
        var profiloTipol = this.t_profili[j];
        if (profiloSel.id == profiloTipol.id)
        {
          profiloSel.isProfiloSel = true;
          profiloTipol.isProfiloSel = true;
          this.eProfiliSel.push(profiloTipol);
        }
      }
    }
    // Svuoto la selezione dalla tabella principale degli enti
    for (let j = 0; j < this.eEnti.length; j++) {
      //var obj = this.eEnti[j];
      this.eEnti[j].isEnteSel = false;
      this.eEnti[j].isUtiliz = false;
      this.eEnti[j].isResp = false;
      this.eEnti[j].eServizi = null;
    }
    // Setto tutti gli enti Selezionati
    for (let i = 0; i < utenteSel.entiUtente.length; i++) {
      var enteSel = utenteSel.entiUtente[i];
      for (let j = 0; j < this.eEnti.length; j++) {
        if (enteSel.idEnte == this.eEnti[j].id)
        {
          enteSel.isEnteSel = true;
          this.eEnti[j].isEnteSel = true;
          this.eEnti[j].isUtiliz = enteSel.isUtiliz;
          this.eEnti[j].isResp = enteSel.isResp;

          // enteSel.idServizio
          ServiziService.getServizio(
            enteSel.idServizio,
            (resp) => {
              console.log("getServizio", resp);
              this.eEnti[j].eServizi = resp;
            },
            (err) => {
              console.log(err);
            }
          );
          //var servTmp = this.getServizio(enteSel.idServizio);
          //this.eEnti[j].eServizi = servTmp;
        }
      }
    }

    this.isHidden = false;
    this.shouldDisable = true;
    this.isEditProfiloUtente = true;
  }

  onNuovoUser() {
    // Chiudo l'oggetto precedente prima di aprire il nuovo
    this.saveClicked = false;
    this.isNewUser = true;

    this.isEditProfiloUtente = true;
    this.shouldDisable = false;
    this.isHidden = true;

    this.profilazioneUtenti.selected = Object.assign({}, this.userTemplate);

    this.onContainerChange();

    this.$refs.form.resetValidation();
  }

  onEditClick() {
    // TODO
    //this.loadUser(this.veicoloSel.id, this.onContainerChange);
    this.shouldDisable = false;
  }

  onDisattivaClick() {
    this.dialogDisattivaUtente = true;
  }

  onResetPwdClick() {
    this.resetPwdDto();
  }

  onSaveClick() {
    // Verifico se i campi della form sono tutti validi.
    // Per funzionare nel template ci deve essere un oggetto di tipo V-FORM
    this.saveClicked = true;
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("erroreCampiObbligatori");
      this.showHide_alertDetail(8000);
    } else {
      // Carico i profili corretti
      this.profilazioneUtenti.selected.ruoliUtente = [];
      for (let i = 0; i < this.t_profili.length; i++) {
        var profilo = this.t_profili[i];
        if (profilo.isProfiloSel)
        {
          var ruoloTmp = Object.assign({}, profilo);
          this.profilazioneUtenti.selected.ruoliUtente.push(ruoloTmp);
        }
      }
      if (this.profilazioneUtenti.selected.ruoliUtente.length <= 0)
      {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreProfiloMancante");
        this.showHide_alertDetail(8000);
        return
      }
      // Carico gli enti correnti
      this.profilazioneUtenti.selected.entiUtente = [];
      for (let i = 0; i < this.eEnti.length; i++) {
        var ente = this.eEnti[i];
        if (ente.isEnteSel)
        {
          var enteUt = [];
          enteUt.cDescrizioneEnte = ente.cDescrizione;
          enteUt.idEnte = ente.id;
          enteUt.idUser = this.profilazioneUtenti.selected.id;
          enteUt.isResp = ente.isResp;
          enteUt.isUtiliz = ente.isUtiliz;
          if (!Utils.isNullOrUndefinedOrEmpty(ente.eServizi))
            //enteUt.idServizio = ente.eServizi[0].id;
            enteUt.idServizio = ente.eServizi.id;

          var enteTmp = Object.assign({}, enteUt);
          this.profilazioneUtenti.selected.entiUtente.push(enteTmp);
        }
      }
      if (this.profilazioneUtenti.selected.entiUtente.length <= 0)
      {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreProfiloMancante");
        this.showHide_alertDetail(8000);
        return
      }

      if (this.profilazioneUtenti.selected.id > 0)
      {
        // Salvo il dato sul DB
        this.saveUserDto();
      }
      else 
      {
        // Creo il nuovo record
        this.createUserDto();
      }
    }
  }

  onCloseDetail() {
    this.isEditProfiloUtente = false;
    this.onContainerChange();
    this.shouldDisable = true;
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblUtenti").offsetWidth;
      return self.dimension;
    });
  }

  onProfiloClick(profilo, checkvalue) {
    if (!this.shouldDisable) {
      // Ciclo tutte i profili e trovo quella modificata
      for (let i = 0; i < this.t_profili.length; i++) {
        var item = this.t_profili[i];
        if (item.id == profilo.id) {
          // Se il profilo è selezionato aggiungo l'id dell'utente altrimenti lo tolgo
          if (checkvalue == true) {
            item.isProfiloSel = true;
            //item.eProfiloCorId = this.profilazioneUtenti.selected.id;
          } else {
            item.isProfiloSel = false;
          }
          break;
        }
      }
      /*
      if (this.buoni.selected.veicoloDTO.id != 0)
        this.buoni.selected.veicoloDTO.scadenze = this.eScadenze;
      else if (this.buoni.selected.attrezDTO.id != 0)
        this.buoni.selected.attrezDTO.scadenze = this.eScadenze;
      */
    }
  }

  onEnteClick(ente, checkvalue)
  {
    if (!this.shouldDisable) {
      // Ciclo tutte gli enti e trovo quella modificata
      for (let i = 0; i < this.eEnti.length; i++) {
        if (this.eEnti[i].id == ente.id) {
          // Se l'Ente è selezionato inserisco il flag altrimenti lo tolgo
          if (checkvalue == true) {
            this.eEnti[i].isEnteSel = true;
          } else {
            this.eEnti[i].isEnteSel = false;
            this.eEnti[i].isResp = false;
            this.eEnti[i].isUtiliz = false;
          }
          break;
        }
      }
      // Necessario per aggiornare il DOM altrimenti i checkbox non vengono aggiornati
      this.$forceUpdate();
    }
  }

  confirmDisattivaUtente() {
    this.disattivaUtenteDto();
  }

  closeDisattivaUtente() {
    this.dialogDisattivaUtente = false;
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  /// ********************************************
  /// DATABASE
  /// ********************************************

  loadUtenti(storico) {
    var self = this;
    self.isloading_dataTable = true;
    UtentiService.getUsers(
      storico,
      (resp) => {
        console.log("GetUsers");
        console.log(resp);
        self.profilazioneUtenti.source = resp;
        self.isloading_dataTable = false;
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
        self.isloading_dataTable = false;
      }
    );
  }

  updateGridUtenti(id, isDisattiva, onSuccess) {
    var self = this;

    UtentiService.getUser(
      id,
      (resp) => {
        console.log("GetUser");
        console.log(resp);
        var utenteTmp = resp;

        if (typeof onSuccess === "function") onSuccess(resp);
        // Aggiorno la griglia
        var found = false;
        for (let i = 0; i < self.profilazioneUtenti.source.length; i++) {
          var item = self.profilazioneUtenti.source[i];
          if (item.id == id) {
            found = true;
            self.profilazioneUtenti.source.splice(i, 1);
            if (!isDisattiva)
              self.profilazioneUtenti.source.push(utenteTmp);
            self.profilazioneUtenti.selected = utenteTmp;
            break;
          }
        }
        if (!found)
        {
          // Caso di nuovo oggetto
          self.profilazioneUtenti.source.push(utenteTmp);
          self.profilazioneUtenti.selected = utenteTmp;
        }

      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
      }
    );
  }

  loadTemplateUtente(storico) {
    var self = this;

    UtentiService.getTemplateUser(
      (resp) => {
        console.log("getTemplateUser");
        console.log(resp);

        self.userTemplate = resp;
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
      }
    );
  }

loadRuoli() {
    var self = this;

    TipologicheService.getTRuoli(
      (resp) => {
        console.log("getTRuoli");
        console.log(resp);

        self.t_profili = resp;
        // Creo una nuova variabile e la setto a false
        for (let i = 0; i < self.t_profili.length; i++) {
          var profilo = self.t_profili[i];
          profilo.isProfiloSel = false;
        } 
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
      }
    );
  }

  loadEnti() {
    var self = this;

    EntiService.getEnti(
      (resp) => {
        console.log("GetEnti");
        console.log(resp);

        self.eEnti = resp;
        // Creo 2 nuove variabili
        for (let i = 0; i < self.eEnti.length; i++) {
          //var obj = self.eEnti[i];
          self.eEnti[i].isResp = false;
          self.eEnti[i].isUtiliz = false;
          self.eEnti[i].isEnteSel = false;

          self.loadServizi(self.eEnti[i].id);
        }

        console.log(self.eEnti);

      },
      (err) => {
        console.log("Errore GetEnti");
        console.log(err);
      }
    );
  }

  loadServizi(idEnte) {
    var self = this;

    ServiziService.getServizi(
      idEnte,
      (resp) => {
        console.log("GetServizi");
        console.log(resp);

        self.eServizi[idEnte] = resp
      },
      (err) => {
        console.log("Errore GetServizi");
        console.log(err);
      }
    );
  }

  getServizio(idServizio) {
    var self = this;

    ServiziService.getServizio(
      idServizio,
      (resp) => {
        console.log("getServizio");
        console.log(resp);

        return resp;
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
      }
    );
  }

  saveUserDto() {
    var self = this;

    UtentiService.updateUser(
      self.profilazioneUtenti.selected,
      (resp) => {
        console.log("UpdateUser");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }

  createUserDto() {
    var self = this;

    UtentiService.createUser(
      self.profilazioneUtenti.selected,
      (resp) => {
        console.log("createUser");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          self.isNewUser = false;
          // Aggiorno la griglia
          self.updateGridUtenti(resp.idNewObject, false, null);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }

  disattivaUtenteDto() {
    var self = this;

    UtentiService.deleteUtente(
      self.profilazioneUtenti.selected.id,
      (resp) => {
        console.log("deleteUtente");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          
          // Ricarico l'oggetto appena salvato ed aggiorno la griglia principale
          self.updateGridUtenti(self.profilazioneUtenti.selected.id, true, null);
          self.isEditProfiloUtente = false;
          self.dialogDisattivaUtente = false;
          self.onContainerChange();

        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
    
  }

  resetPwdDto() {
    var self = this;

    UtentiService.resetPassword(
      self.profilazioneUtenti.selected,
      (resp) => {
        console.log("resetPassword");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }

}
</script>