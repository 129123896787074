import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Servizi/';

class ServiziService {
    getServizi(enteId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("enteId", enteId);
        
        UtilsRequest.get(API_URL + "GetServizi", parametri,
        successFn,
        failFn
        );
    }

    getServizio(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idServizio", id);

        UtilsRequest.get(API_URL + "GetServizio", parametri,
        successFn,
        failFn
        );
    }
    
    getTemplateServizio(successFn, failFn){
        const parametri = new URLSearchParams();

        UtilsRequest.get(API_URL + "GetTemplateServizio", null,
        successFn,
        failFn
        );
    }

    createServizio(servizio, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateServizio", servizio, 
        successFn,
        failFn
        );
    }

    updateServizio(servizio, successFn, failFn){
        UtilsRequest.put(API_URL + "UpdateServizio", servizio, 
        successFn,
        failFn
        );
    }

    deleteServizio(id, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("id", id);
        
        UtilsRequest.delete(API_URL + "DeleteServizio", parametri, 
        successFn,
        failFn
        );
    }
}

export default new ServiziService();
